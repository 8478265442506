export const homeAbout = [
    {
      id: 1,
      cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
      title: "Good Quality Advice and Affordability",
      desc: "Our certified instructors provide qood quality advice and the courses are affordable.",
    },
    {
      id: 1,
      cover: "https://img.icons8.com/ios/80/000000/diploma.png",
      title: "Earn A Certificate",
      desc: "We are Licensed by the New York Department of State and approved by the Division of Criminal Justice Services (DCJS).",
    },
    {
      id: 1,
      cover: "https://img.icons8.com/ios/80/000000/athlete.png",
      title: "Learn with Experts",
      desc: "Our instructors are fully certified with years of experience. Clear lines of communication is established with students during training session.",
    },
  ]
  export const awrapper = [
    {
      cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
      data: "3,000",
      title: "SUCCESS STORIES",
    },
  
    {
      cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
      data: "320",
      title: "TRUSTED TUTORS",
    },
    {
      cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
      data: "1,000",
      title: "SCHEDULES",
    },
    {
      cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
      data: "587",
      title: "COURSES",
    },
  ]
  export const coursesCard = [
    {
      id: 1,
      cover: "../images/courses/c1.png",
      coursesName: "8 Hour Pre-Assignment Training",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "5 lectures (8 hrs)",
        },
      ],
      priceAll: "$80",
      pricePer: "$20 per month",
    },
    {
      id: 2,
      cover: "../images/courses/c2.png",
      coursesName: "16 Hour On-The-Job Training (OJT)",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "7 lectures (16 hrs)",
        },
      ],
      priceAll: "$150",
      pricePer: "$50 per month",
    },
    {
      id: 3,
      cover: "../images/courses/c3.png",
      coursesName: "8 Hour Annual In-Service Training",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "5 lectures (8 hrs)",
        },
      ],
      priceAll: "$80",
      pricePer: "$5 per month",
    },
    {
      id: 4,
      cover: "../images/courses/c4.png",
      coursesName: "6-Hour Defensive Driving Class",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "20 lectures (20 hrs)",
        },
      ],
      priceAll: "$80 All Course",
      pricePer: "$3 per month",
    },
    {
      id: 5,
      cover: "../images/courses/c5.png",
      coursesName: "CPR Training Class",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "100 lectures (150 hrs)",
        },
      ],
      priceAll: "$100 All Course",
      pricePer: "$30 per month",
    },
    {
      id: 6,
      cover: "../images/courses/c6.png",
      coursesName: "First Aid Class",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "200 lectures (300 hrs)",
        },
      ],
      priceAll: "$100 All Course",
      pricePer: "$80 per month",
    },
    {
      id: 7,
      cover: "../images/courses/c7.png",
      coursesName: "AED Class",
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Mo",
          totalTime: "20 lectures (50 hrs)",
        },
      ],
      priceAll: "$100 All Course",
      pricePer: "$2 per month",
    },
  ]
  export const online = [
    {
      cover: "./images/courses/online/o1.png",
      hoverCover: "./images/courses/online/o1.1.png",
      courseName: "UI/UX Design Courses",
      course: "25 Courses",
    },
    {
      cover: "./images/courses/online/o2.png",
      hoverCover: "./images/courses/online/o2.1.png",
      courseName: "Art & Design",
      course: "25 Courses",
    },
    {
      cover: "./images/courses/online/o3.png",
      hoverCover: "./images/courses/online/o3.1.png",
      courseName: "Computer Science",
      course: "10 Courses",
    },
    {
      cover: "./images/courses/online/o4.png",
      hoverCover: "./images/courses/online/o4.1.png",
      courseName: "History & Archeologic",
      course: "15 Courses",
    },
    {
      cover: "./images/courses/online/o5.png",
      hoverCover: "./images/courses/online/o5.1.png",
      courseName: "Software Engineering",
      course: "30 Courses",
    },
    {
      cover: "./images/courses/online/o6.png",
      hoverCover: "./images/courses/online/o6.1.png",
      courseName: "Information Software",
      course: "80 Courses",
    },
    {
      cover: "./images/courses/online/o7.png",
      hoverCover: "./images/courses/online/o7.1.png",
      courseName: "Health & Fitness",
      course: "10 Courses",
    },
    {
      cover: "./images/courses/online/o8.png",
      hoverCover: "./images/courses/online/o8.1.png",
      courseName: "Marketing",
      course: "30 Courses",
    },
    {
      cover: "./images/courses/online/o9.png",
      hoverCover: "./images/courses/online/o9.1.png",
      courseName: "Graphic Design",
      course: "80 Courses",
    },
    {
      cover: "./images/courses/online/o10.png",
      hoverCover: "./images/courses/online/o10.1.png",
      courseName: "Music",
      course: "120 Courses",
    },
    {
      cover: "./images/courses/online/o11.png",
      hoverCover: "./images/courses/online/o11.1.png",
      courseName: "Business Administration",
      course: "17 Courses",
    },
    {
      cover: "./images/courses/online/o12.png",
      hoverCover: "./images/courses/online/o12.1.png",
      courseName: "Web Management",
      course: "17 Courses",
    },
  ]
  export const team = [
    {
      cover: "./images/team/t1.webp",
      name: "Mo Agbaje, CPP",
      work: "PRESIDENT / CEO",
    },
    {
      cover: "./images/team/t5.webp",
      name: "Rebecca Idowu",
      work: "VICE PRESIDENT",
    },
    {
      cover: "./images/team/t0.webp",
      name: "Mike Abi",
      work: "ASST. VICE PRESIDENT",
    },
    {
      cover: "./images/team/t0.webp",
      name: "Ray Agbaje",
      work: "SUPERVISOR, FINGERPRINT OPERATIONS",
    },
  ]
  export const price = [
    {
      name: "8 Hour Pre-Assignment Training",
      price: "80",
      desc: "A general introductory course. You must complete this course and submit a copy of the certificate issued to you with your security guard application.",
    },
    {
      name: "16 Hour On-The-Job Training (OJT)",
      price: "150",
      desc: "A course relevant to the duties of guards, requirements of the work site, and the needs of the employer. You must complete this training within 90 days of employment as a security guard.",
    },
    {
      name: "8 Hour Annual In-Service Training",
      price: "80",
      desc: "This course must be completed each calendar year you hold a security guard registration. Your registration is issued for two years, therefore, you must complete two 8 Hour Annual In-Service training courses within your registration effective and expiration dates to be eligible for renewal.",
    },
    {
      name: "NEW LICENSE",
      price: "400",
      desc: "The following training courses are required for all persons getting a security guard license for the first time; 8 hour course & 16 hour course. Fingerprint and application cost is also included in the fee.",
    },
  ]
  export const faq = [
    {
      title: "How to Enroll in a Courses?",
      desc: "As of the moment, online enrollment is not available. Call our office number to enroll for classes",
    },
    {
      title: "What days of the week are the classes?",
      desc: "Classes for new licensee are from Tuesdays to Thursdays. Renewals are on Fridays and Saturdays",
    },
    {
      title: "How do I pay for the classes",
      desc: "We accept cash or money order",
    },
  ]
  export const blog = [
    {
      id: 1,
      type: "admin",
      date: "JAN. 18, 2021",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b1.webp",
    },
    {
      id: 2,
      type: "admin",
      date: "API. 25, 2022",
      com: "5 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b2.webp",
    },
    {
      id: 3,
      type: "user",
      date: "MAY. 15, 2022",
      com: "10 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b3.webp",
    },
    {
      id: 4,
      type: "admin",
      date: "JAN. 02, 2022",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b4.webp",
    },
    {
      id: 5,
      type: "admin",
      date: "DEC. 14, 2022",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b5.webp",
    },
    {
      id: 6,
      type: "user",
      date: "JAN. 18, 2021",
      com: "12 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b6.webp",
    },
  ]
  export const testimonal = [
    {
      id: 1,
      name: "Angie Fermin",
      post: "5 reviews",
      desc: "Very professional, good quality advice and affordable. Good courses. They also do the whole security license process for you. So you don’t have to search and schedule fingerprinting and application yourself.",
      cover: "https://lh3.googleusercontent.com/a-/AFdZuco2NcmTW0otEaFw_xG2QhfQ4hqOv_sFx5swOpKe3w=w120-h120-p-rp-mo-br100",
    },
    {
      id: 2,
      name: "Lisa Daniels",
      post: "1 review",
      desc: "One of the best security training schools I know. The staff is very friendly,  respectful and understanding of your needs. They are not money hungry and are willing to work with you. They are legitimate and fully licensed. This is the only company I've been going to for years now.",
      cover: "https://lh3.googleusercontent.com/a-/AFdZucr9aXTrMBdZTnB4jwwmhCYiLRv9oqtFF4e4Jwcsfw=w120-h120-p-rp-mo-br100",
    },
    {
      id: 3,
      name: "J Jackson",
      post: "1 review",
      desc: "Best training school I have ever been too. Every one there is kind, welcoming. I been coming here for years and I wouldn’t go to any other security guard training  school but continum. You guys are awesome.",
      cover: "https://lh3.googleusercontent.com/a/AItbvmk3ilEu_stnmDWbUXMo5tcDgR0TCxFk2wHDWPEOIg=w120-h120-p-rp-mo-br100",
    },
  ]